.form {
  margin: 1rem 0;
  @include flexColumn;
  //  width: 40rem;

  @include respond(tab-land) {
    //  width: 38rem;
  }

  @include respond(medium-screen) {
    //    width: 34rem;
  }

  @include respond(tab-port) {
    //   width: 31rem;
  }

  @include respond(phone) {
    min-width: 100%;
    //  width: 40rem;
  }

  &__input {
    margin: 1rem 0;
    //background: #D8D8D8;
    border: 1px solid #979797;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: $font-size-small;
    padding: 1.4rem 1.6rem;
    color: #999999;
    outline: none;
    //position: relative;

    &--2 {
      // width: 48%;
      width: 100%;
      left: 0;
    }
  }

  &__button {
    margin-top: 2.4rem;
  }
}

.cityDropdown {
  margin: 1rem 0;
  //background: #D8D8D8;
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;

  // font-size: $font-size-small;

  font-size: 1.6rem !important;
  margin-top: 9px !important;
  color: #999999 !important;
  outline: none !important;
  // width: 48%;
  left: 0;
}

.Select-value-label {
  color: #999999 !important;
  outline: none !important;
  outline-color: none !important;
}

.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none !important;
}

.checkmark {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.87;
  margin-left: 8px;

  color: #2d2d45;
}

.select-css {
  display: block;
  padding: 1.4rem 1.6rem;
  border-radius: 4px;
  font-size: $font-size-small;
  color: #999999;
  border: 1px solid #979797;

  width: 50%;
  max-width: 50%;

  margin: 1rem 0 1rem 0.8rem;

  appearance: none;
  background-color: #fff;
}

.select-css::-ms-expand {
  display: none;
}

.select-css:hover {
}

.select-css:focus {
  outline: none;
}

.select-css option {
  font-weight: normal;
}

.successPopup {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  //background: rgba(0, 0, 0, 0.4);
  @include flexCenter;
}

.successPopup__content {
  background-color: #009ae0;
  padding: 3rem 6rem;
  border-radius: 5px;
  width: 30%;
  text-align: center;

  @include respond(phone) {
    width: 80%;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1000;
  }

  // 10% {
  //     opacity: 0.9;
  // }

  // 20% {
  //     opacity: 0.8;
  // }

  // 30% {
  //     opacity: 0.7;
  // }

  // 40% {
  //     opacity: 0.6;
  // }

  // 50% {
  //     opacity: 0.5;

  // }

  // 60% {
  //     opacity: 0.4;
  // }

  // 70% {
  //     opacity: 0.3;
  // }

  // 80% {
  //     opacity: 0.2;
  // }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
    z-index: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1000;
  }

  // 10% {
  //     opacity: 0.9;
  // }

  // 20% {
  //     opacity: 0.8;
  // }

  // 30% {
  //     opacity: 0.7;
  // }

  // 40% {
  //     opacity: 0.6;
  // }

  // 50% {
  //     opacity: 0.5;

  // }

  // 60% {
  //     opacity: 0.4;
  // }

  // 70% {
  //     opacity: 0.3;
  // }

  // 80% {
  //     opacity: 0.2;
  // }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
    z-index: 0;
    visibility: hidden;
  }
}

.successOut {
  //  display: block;
  -webkit-animation: fadeOut 5s;
  animation: fadeOut 5s;
  animation-fill-mode: forwards;
}

.whatsappBtn {
  background: #33cc66;
  border-radius: 4px;
}

.whatsappAnchor {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  // padding: 0 4px;
}

.whatsappAnchor:hover {
  color: #ffffff;
}

.spacing {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: #999999;
}

.VideoModal {
  display: flex;
  display: -webkit-flexbox;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);

  &__Container {
    background-color: #ffffff;
    margin: auto;
    border: 1px solid #888;
    width: 60%;
    @include respond(phone) {
      width: 100%;
    }
    border-radius: 1%;
    padding: 15px 20px 20px;

    &--Head {
      font-size: 20px;
      font-weight: 700;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
    }
    &--Head img {
      cursor: pointer;
    }

    &--Body iframe {
      width: 100%;
      height: 515px;

      @include respond(phone) {
        height: 200px;
      }
      border: none;
    }
  }
}
