*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    scroll-behavior: smooth;
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    @include respond(tab-land) {
        // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) {
        // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }

    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

body {
    box-sizing: border-box;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include respond(tab-port) {
        padding: 0;
    }
}

::selection {
    background-color: $color-primary-blue;
    color: $color-primary-white;
}


::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1573e3;
    background: #485465;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1573e3;
    border-radius: 10px;
}