.navigation {
  padding: 1rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-primary-white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;

  @include respond(tab-land) {
    padding: 0.5rem 5rem;
  }

  @include respond(large-screen) {
    padding: 0.5rem 5rem;
  }

  @include respond(medium-screen) {
    padding: 0.5rem 5rem;
  }

  @include respond(ipad) {
    padding: 1rem 5rem;
  }

  @include respond(phone) {
    padding: 1rem 2rem;
  }

  &__logo {
    &--img {
      width: 100%;

      @include respond(phone) {
        width: 16rem;
        height: 4rem;
      }
    }
  }
}

.scrollButton {
  border-radius: 50px;
  font-weight: 600;
  padding: 1rem 3.1rem;
}
