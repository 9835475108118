.tnc {
    margin-top: 16rem;
    text-align: center;

    @include respond(phone) {
        margin-top: 8rem;
    }
}

.tnc__heading {
    font-weight: 700;
    font-size: 4rem;
    line-height: 3rem;

    &--2 {
        margin-top: 2rem;
        color: #777777;
        line-height: 30px;
        font-size: 24px;
    }

}

.tnc__heading::after {
    content: "";
    border-top: 1px solid #333333;
    margin-top: 1rem;
}

.tnc__list {
    text-align: left;
    margin: 3rem 0;
    padding: 0 10rem;
}

.tnc__item {
    margin: 3rem 0;

}

.tnc__item--title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #444444;
}

.tnc__item--content {
    margin: 1rem 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #555555;
}

.policy {
    text-align: center;
    font-size: 1.6rem;

    &__content {
        margin: 3rem 0;
        padding: 1rem 11rem;

        @include respond(phone) {
            padding: 1rem 4rem;
        }
    }

    &__list {
        text-align: left;
        padding: 1rem 14rem;

        @include respond(phone) {
            padding: 1rem 4rem;
        }

        &--item {
            list-style: circle;
        }
    }
}

@media only screen and (max-width: 900px) {
    .tnc__list {
        text-align: left;
        margin: 2rem 0;
        padding: 0 5rem;
    }
}

@media only screen and (max-width: 768px) {
    .tnc__list {
        text-align: left;
        margin: 2rem 0;
        padding: 0 4rem;
    }
}

@media only screen and (max-width: 600px) {
    .tnc__heading {
        font-weight: 700;
        font-size: 3rem;
        line-height: 2.25rem;

    }

    .tnc__list {
        text-align: left;
        margin: 2rem 0;
        padding: 0 3rem;
    }

    .tnc__item {
        margin: 2rem 0;

    }

    .tnc__item--title {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        color: #444444;
    }

    .tnc__item--content {
        margin: 1rem 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #555555;
    }

}

@media only screen and (max-width: 480px) {
    .tnc__list {
        text-align: left;
        margin: 2rem 0;
        padding: 0 2rem;
    }
}


@media only screen and (max-width: 320px) {
    .tnc__list {
        text-align: left;
        margin: 2rem 0;
        padding: 0 1rem;
    }
}