.Institutes {
    padding: 12rem 0 9rem 0;

    @include respond (phone) {
        padding: 6rem 0;
    }

    &--heading {
        @include respond (phone) {
            padding: 0 4rem;
        }
    }

    ;
    text-align: center;
    background-color: $color-secondary-grey;

    &--text {
        padding: 0 14rem;

        @include respond (phone) {
            padding: 0 2.4rem;
        }

    }

    &--content {
        margin-top: 3.4rem;
    }

    &--list {
        margin-top: 6.2rem;

        // width: 1920px;
        // overflow: hidden;

        &--profile {
            margin: 0 1rem;
            width: unset !important;

            &--img {
                border-radius: 50%;
                height: 9rem;
                width: 9rem;
                object-fit: cover;
                object-position: center;
            }

            &--text {
                margin-top: 1rem;
                @include flexColumn
            }
        }
    }
}



// .photobanner {

//     width: 3550px;
//     padding: 2rem 0;
// }

// .photobanner span {
//     -webkit-transition: all 0.5s ease;
//     -moz-transition: all 0.5s ease;
//     -o-transition: all 0.5s ease;
//     -ms-transition: all 0.5s ease;
//     transition: all 0.5s ease;
// }

// .photobanner span:hover {
//     -webkit-transform: scale(1.1);
//     -moz-transform: scale(1.1);
//     -o-transform: scale(1.1);
//     -ms-transform: scale(1.1);
//     transform: scale(1.1);
//     cursor: pointer;

//     -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
//     -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
//     box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
// }

// /*keyframe animations*/
// .first {
//     -webkit-animation: bannermove 30s linear infinite;
//     -moz-animation: bannermove 30s linear infinite;
//     -ms-animation: bannermove 30s linear infinite;
//     -o-animation: bannermove 30s linear infinite;
//     animation: bannermove 30s linear infinite;
// }

// @keyframes bannermove {
//     0% {
//         margin-left: 0px;
//     }

//     100% {
//         margin-left: -2125px;
//     }

// }

// @-moz-keyframes bannermove {
//     0% {
//         margin-left: 0px;
//     }

//     100% {
//         margin-left: -2125px;
//     }

// }

// @-webkit-keyframes bannermove {
//     0% {
//         margin-left: 0px;
//     }

//     100% {
//         margin-left: -2125px;
//     }

// }

// @-ms-keyframes bannermove {
//     0% {
//         margin-left: 0px;
//     }

//     100% {
//         margin-left: -2125px;
//     }

// }

// @-o-keyframes bannermove {
//     0% {
//         margin-left: 0px;
//     }

//     100% {
//         margin-left: -2125px;
//     }

// }



.Institutes--list .slick-slide img {
    display: initial !important;
}

.Institutes--list .slick-arrow {
    display: none !important;
}

.Institutes--list .slick-dots>* {
    display: none !important;
}