.News {
    padding: 6rem 12rem;

    @include respond (phone) {
        padding: 6rem 2.4rem;
    }


    &__header {
        text-align: center;

        &--heading {}

        &--content {
            margin-top: 2.4rem;
            font-weight: 700;
        }
    }

    &__list {
        margin-top: 4.8rem;

        @include respond (ipad) {
            margin-top: 0rem;
        }


        &--item {
            text-align: center;

            @include respond(phone) {
               margin-top:2rem;
            }

            &--img {
                width: 15rem;
                height: 4.8rem;
                // border-radius: 50%;
            }

            &--heading {
                margin-top: 1.6rem;
                font-weight: $font-weight-bold;
            }

            &--content {
                margin-top: 1.8rem;
                color: #2d2d45;

                @include respond(phone) {
                    color: $color-primary-green;
                    padding:0 1rem;
                }
            }
        }
    }
}