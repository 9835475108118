.Contact {
    padding: 6rem 20rem;
    text-align: center;


    @include respond(phone) {
        padding: 3rem 0rem;
    }

    &__heading {
        font-size:3.8rem;
        font-weight:700;
        line-height:4rem;

        @include respond(phone) {
            font-size:2.4rem
        }
    }
    &__content {
        margin-top: 3.4rem;
        padding: 0 2.4rem;

        @include respond(phone) {
            margin-top:1.5rem;
        }
    }

    &--form {

        margin-top: 3rem;
        margin: 0 auto;
        width: 40%;

        // padding: 0 32rem;
        @include flexCenter;
        -ms-grid-column-align: center;
        justify-self: center;

        @include respond(phone) {
            
            padding: 0 2.6rem;
            width: 100%;
        }
    }
}