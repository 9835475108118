.Footer {
    background-color: $color-background-secondary;
    padding: 6rem 15rem;
    color: $color-primary-white;
    @include flexColumn;

    @include respond(large-screen) {
        padding: 4rem 12rem;
    }

    @include respond(tab-port) {
        padding: 2rem 12rem;
    }

    @include respond(phone) {
        padding: 6rem 2.4rem;
    }

    &__content {

        padding-bottom: 4.8rem;

        @include respond(phone) {
            text-align: center;
        }


        &--title {
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
            line-height: 2rem;
            margin-bottom: 2.2rem;

            @include respond(phone) {
                margin-bottom: 1.7rem;

            }
        }

        &--company {
            &--item {
                margin-bottom: 0.8rem;
                color: #FFFFFF;
                text-decoration: none
            }
        }


        &--contact {
            @include flexColumn;

            @include respond(phone) {
                margin-top: 2rem;
            }
        }

        &--address {
            font-size: $font-size-small-extra;
        }

        &--phone {
            margin-top: 1.6rem;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: $font-weight-medium;

            &--img {
                margin-right: 1rem;
            }

            &--extra {
                @include respond(phone) {
                    text-align: center;
                }
            }
        }

        &--reach {
            text-align: left;


            @include respond(ipad) {
                text-align: center;

            }

            @include respond(phone) {
                margin-top: 2rem;
            }
        }

        &--contacts {
            margin-left: 2.2rem;

            @include respond(phone) {

                margin-left: 0rem;
            }
        }

        &--contacts>* {
            margin-left: 1.7rem;

            @include respond(tab-port) {
                margin-left: 1rem;
            }

            @include respond(phone) {

                margin-right: 0.5rem;
            }
        }

    }


    &__copyrights {
        padding-top: 3.2rem;
        border-top: 1px solid #FFFFFF;
        text-align: center;
    }
}

.classplusFooter {
    @include respond(phone) {
        display: none
    }

}

.jcCheat {
    @include respond(phone) {
        justify-content: center;
    }
}