.btn {
    @include btn;
    transition: all 0.3s ease-in;

    &--bookADemo {

        padding: 1.6rem 3.4rem;
        font-size: $font-size-small;
        border-radius: 4px;
        background-color: $color-primary-blue;
        color: $color-primary-white;
        outline: none;
        border: none;
        font-weight: 600;
        cursor: pointer;
        @include respond(phone) {
            padding: 1.4rem 1.6rem;
        }
    }
}

.btn:hover {
    transform: translateY(-0.2rem);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

button {
    outline: none;
    border:none;
}

.btnedges {
    border-radius: 50px;
}