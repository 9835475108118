//the mixins for work improvement
@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
-medium-screen
-desktop
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/

@mixin respond($breakpoint) {
    @if $breakpoint==port-phone {
        @media only screen and (max-width: 20em) {
            @content
        }

        ; //320px
    }

    @if $breakpoint==land-phone {
        @media only screen and (max-width: 30em) {
            @content
        }

        ; //480px
    }

    @if $breakpoint==phone {
        @media only screen and (max-width: 37.5em) {
            @content
        }

        ; //600px
    }

    @if $breakpoint==ipad {
        @media only screen and (max-width: 48em) {
            @content
        }

        ; //768px
    }

    @if $breakpoint==tab-port {
        @media only screen and (max-width: 56.25em) {
            @content
        }

        ; //900px
    }

    @if $breakpoint==medium-screen {
        @media only screen and (max-width: 64em) {
            @content
        }

        ; //1024px
    }

    @if $breakpoint==large-screen {
        @media only screen and (max-width: 64em) {
            @content
        }

        ; //1152px
    }


    @if $breakpoint==tab-land {
        @media only screen and (max-width: 80em) {
            @content
        }

        ; //1280px
    }

    @if $breakpoint==desktop {
        @media only screen and (max-width: 90em) {
            @content
        }

        ; //1440px
    }

    @if $breakpoint==big-desktop {
        @media only screen and (min-width: 112.5em) {
            @content
        }

        ; //1800
    }
}

@mixin flexRow {
    display: flex;

}

@mixin flexColumn {
    display: flex !important;
    flex-direction: column !important;
}

@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

@mixin flexStart {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

@mixin flexEnd {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}


@mixin gridCenter {
    display: grid;
    justify-items: center; //Aligns content in a grid item along the row axis.
    align-items: center; //Aligns content in a grid item along the column axis
    justify-content: center; //Justifies all grid content on row axis when total grid size is smaller than container
    align-content: center; //Justifies all grid content on column axis when total grid size is smaller than container.
}

@mixin gridStart {
    display: grid;
    justify-items: start;
    align-items: center;
    align-content: center
}

@mixin gridEnd {
    display: grid;
    justify-items: end;
    align-items: center;
    align-content: center
}

@mixin btn {
    box-shadow: none;
    text-shadow: none;
    border: none;

}