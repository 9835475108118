.features {
    padding: 6rem 9rem 0 12rem;

    @include respond (phone) {
        padding: 6rem 0rem 3rem 0;
    }

    text-align: center;

    &__header {
        &--heading {
            @include respond (phone) {
              font-size:2.4rem
            }
        }

        &--content {
            margin-top: 3.4rem;
            padding: 0 2.4rem;
        }
    }

    &__content {
        // margin-top: 8rem;

        @include respond(phone) {
            //   margin-top: 2rem;
        }

        &--list {

            margin-top: 4rem;
            padding: 0 9rem;
            //  padding: 7.5rem 30rem;

            @include respond(tab-land) {
                padding:0;
            }
            @include respond(phone) {
                //  margin-top: 9rem;
                margin-top: 2rem;
            }

            &--item {
                text-align: center;
                margin: 2rem;
                &--img {
                    width: 6.4rem;
                    height: 6.4rem;
                    margin:1rem 2rem;
                    // border-radius: 50%;
                }

                &--heading {
                   // margin-top: 1.6rem;
                    font-weight: $font-weight-bold;
                    font-size: 1.8rem;
                    margin-bottom: 0.5rem;

                    @include respond(phone) {
                        font-weight: 700;
                        font-size: 2.0rem;
                        margin-top:0rem;
                    }
                }

                &--content {
                   // margin-top: 1.8rem;
                }
            }


        }

    }

    &__link {
        margin-top: 4rem;

        a {
            font-size: 18px;
            line-height: 27px;
            text-align: center;

            color: #9013FE;
            text-decoration: none;

        }
    }
}


.features__content .slick-prev,
.features__content .slick-next {
    display: flex !important;
    content: "" !important;
    margin: 0 1rem !important;
    list-style: none;
    visibility: hidden;
    position: relative;
}

.features__content .slick-prev::after {
    display: none;
    position: absolute;
    top: 25rem;
    left: -5rem;
    content: "";
    height: 16px;
    width: 16px;
    background-color: #009ae0;
    border-radius: 50%;
}


.features__content .slick-next::after {
    display: none;
    position: absolute;
    bottom: 28rem;
    right: -125rem;
    content: "";
    height: 16px;
    width: 16px;
    background-color: #009ae0;
    border-radius: 50%;


}

.features__content .slick-dots {
    display: flex !important;
    justify-content: center;
}

.features__content .slick-dots li {
    content: "" !important;
    margin: 0 1rem !important;
    list-style: none;
    height: 15px;
    width: 15px;
    background-color: rgba(0, 154, 224, 0.24);
    border-radius: 50%;

    // visibility: hidden;
    //position: relative;
}

.features__content .slick-dots li button {
    content: "" !important;
    color: transparent;
    background-color: transparent;
    border: none;
    outline: none;
    height: 10px;
}

.features__content .slick-dots li:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 15px;
    width: 15px;
    // background-color: #009ae0;
    border-radius: 50%;
}


.features__content .slick-dots .slick-active {

    background-color: #009ae0;

}


.features__content .slick-dots .slick-active button {

    background-color: #009ae0;
    color: #009ae0;

}

.features__content .slick-active,
.features__content .slick-current {
    outline: none !important;
}

.features__cheat {
    padding: 6rem 20rem;
    background-color: #F5FDFE;

    @include respond(large-screen) {
        padding:4rem 10rem
    }
    @include respond(phone) {
        padding: 3rem 6rem;
    }
}