@import './scss/main.scss';

.book-demo-wrapper {
  padding: 16px 12px;
  background: #fffffe !important;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;

  z-index: 99;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  border: none;
  color: #fff;
}
.book-demo {
  width: inherit;
  /* height: 40px; */
  padding: 0 8px;
  line-height: 40px;
  /* position: fixed;
    bottom: 0;
    left: 0; */
  font-size: 16px;
  border-radius: 4px;

  background: #009ae0 !important;
}

.closeBtn {
  bottom: -100%;
}

#chat-bot-launcher-container {
  /* top: 83% !important; */
  bottom: 10% !important;
}
